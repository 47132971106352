import { Component, Input, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-brands-carousel',
  templateUrl: './brands-carousel.component.html',
  styleUrls: ['./brands-carousel.component.scss']
})
export class BrandsCarouselComponent {

  @Input('brands') brands: Array<any> = [];

  @ViewChild(SwiperDirective, { static: false }) swiperDirective: SwiperDirective;
  public config: SwiperConfigInterface = {
    observer: false,
    slidesPerView: 'auto',
    spaceBetween: 48,
    keyboard: {
      enabled: true,
    },
    navigation: true,
    pagination: false,
    grabCursor: true,
    loop: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 10, // Number of slides to load before and after the active slide
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    speed: 500,
    effect: 'slide',
  }
  
  constructor() { }

  ngAfterViewInit(){
    // this.config = {
    //   slidesPerView: 7,
    //   spaceBetween: 16,         
    //   keyboard: true,
    //   navigation: true,
    //   pagination: false,
    //   grabCursor: true,  
    //   loop: false,
    //   preloadImages: false,
    //   lazy: true,
    //   autoplay: {
    //     delay: 5000,
    //     disableOnInteraction: false
    //   },
    //   speed: 500,
    //   effect: "slide",
    //   breakpoints: {
    //     200: {
    //       slidesPerView: 10
    //     }
    //   },
    //   observer: false,
    //   observeParents: false,
    // }
    if (this.swiperDirective && this.swiperDirective.swiper()) {
      const swiperInstance = this.swiperDirective.swiper();
      swiperInstance.on('slideChangeTransitionStart', () => {
        swiperInstance.autoplay.stop();
        swiperInstance.autoplay.start();
      });
    }
  }
}