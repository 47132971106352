import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input('banners') banners: Array<any> = [];
  @Input('updateSwiperConfig') updateSwiperConfig: Subject<boolean>;
  @ViewChild(SwiperDirective, { static: false }) swiperDirective: SwiperDirective;
  public config: SwiperConfigInterface = {
    observer: false,
    slidesPerView: 'auto',
    spaceBetween: 16,
    keyboard: {
      enabled: true,
    },
    navigation: true,
    pagination: false,
    grabCursor: true,
    loop: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 10, // Number of slides to load before and after the active slide
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    speed: 500,
    effect: 'slide',
  }

  public count: any;
  public page: any;

  constructor() { }

  title = 'Card View Demo';

  gridColumns = 3;

  toggleGridColumns() {
    this.gridColumns = this.gridColumns === 3 ? 4 : 3;
  }

  ngOnInit() {  
     this.updateSwiperConfig?.subscribe(v => {
      this.config.observer = v;
    });
  }

  ngAfterViewInit(){
    // this.config = {
    //   observer: true,
    //   slidesPerView: 1,
    //   spaceBetween: 16,       
    //   keyboard: true,
    //   navigation: true,
    //   pagination: false,
    //   grabCursor: true,        
    //   loop: false,
    //   preloadImages: false,
    //   lazy: true,
    //   autoplay: {
    //     delay: 5000,
    //     disableOnInteraction: false
    //   },
    //   speed: 500,
    //   effect: "slide",
    //   breakpoints: {
    //     200: {
    //       slidesPerView: 10
    //     }
    //   }
    // }
    if (this.swiperDirective && this.swiperDirective.swiper()) {
      const swiperInstance = this.swiperDirective.swiper();
      swiperInstance.on('slideChangeTransitionStart', () => {
        swiperInstance.autoplay.stop();
        swiperInstance.autoplay.start();
      });
    }
  }

  public getBanner(index){
    return this.banners[index];
  }

  public getBgImage(index){
    let bgImage = {
      'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
    };
    return bgImage;
  }
  
 

}
