import { Injectable } from "@angular/core";
import {
    Router,
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { forkJoin, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppService } from "../app.service";

@Injectable({
    providedIn: "root",
})
export class PagesResolverService implements Resolve<any> {
    isLoggedIn: boolean = false;

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private router: Router
    ) { }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        let categories = this.appService.getCategories().pipe(
            catchError((err) => {
                if (err.status === 401) {
                    localStorage.removeItem("access_token");
                    location.reload();
                    return throwError(() => err);
                }
            })
        );
        let join = forkJoin([categories]).pipe(map((allResponses: any) => {
            return {
                categories: allResponses[0].data
            };
        }))
        return join;
    }
}