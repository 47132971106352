import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  user: any;
  categories: any = [];
  selectedParentCategory: any = [];
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  //category: any;
  constructor(private activeRoute: ActivatedRoute, public appService: AppService, private ren: Renderer2) { }

  ngOnInit() {
    this.getCategories();
    this.activeRoute.data.subscribe((data: any) => {
      this.user = data.user;
    });
  }

  public getCategories() {
    this.activeRoute.data.subscribe((data: any) => {
      data.pages.categories.forEach((category: any) => {
        if (category.slug != 'all') {
          this.categories.push(category);
        }
      });
      this.categories.forEach((category: any, index: any) => {
        if (category.hasSubCategory) {
          category.subCategories = this.categories.filter((cat: any) => cat.parentId == category.id);
        }
      });
      //this.category = data[0];
      this.appService.Data.categories = this.categories;
    });
  }

  openMegaMenu(category: any) {
    this.selectedParentCategory = category;
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

  openSubMenu(menuTrigger: MatMenuTrigger, category: any) {
    menuTrigger.openMenu();
    this.selectedParentCategory = category;
    // let pane = document.getElementsByClassName('cdk-overlay-pane');
    // [].forEach.call(pane, function (el) {
    //     if(el.children.length > 0){
    //       if(el.children[0].classList.contains('mega-menu')){
    //         el.classList.add('mega-menu-pane');
    //       }
    //     }
    // });
  }

  closeSubMenu(menuTrigger: MatMenuTrigger, category: any) {
    menuTrigger.closeMenu();
  }

  buttonEnter(trigger: any, category: any) {
    setTimeout(() => {
      this.selectedParentCategory = category;
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        if (trigger.menu.items.length > 0) {
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
        if (trigger.menu.items.length > 0) {
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        }
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
      let pane = document.getElementsByClassName('cdk-overlay-pane');
      [].forEach.call(pane, function (el) {
        if (el.children.length > 0) {
          if (el.children[0].classList.contains('mega-menu')) {
            el.classList.add('mega-menu-pane');
          }
        }
      });
    })
  }

  buttonLeave(trigger: any, button: any) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger: any, button: any) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }
}