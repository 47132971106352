import { Injectable } from "@angular/core";
import {
    Router,
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { forkJoin, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppService } from "../app.service";

@Injectable({
    providedIn: "root",
})
export class AddressResolverService implements Resolve<any> {
    isLoggedIn: boolean = false;

    constructor(
        private appService: AppService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        let billingAddress = this.appService.getBillingAddressById(null).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    localStorage.removeItem("access_token");
                    location.reload();
                    return throwError(() => err);
                }
            })
        );
        let shippingAddress = this.appService.getShippingAddressById(null).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    localStorage.removeItem("access_token");
                    location.reload();
                    return throwError(() => err);
                }
            })
        );
        let join = forkJoin([billingAddress, shippingAddress]).pipe(map((allResponses: any) => {
            return {
                billingAddress: allResponses[0],
                shippingAddress: allResponses[1]
            };
        }))
        return join;
    }
}
