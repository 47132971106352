import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    readonly registerApi = environment.ATTIVEG_BACKEND_HOST + "api/register";
    readonly getCurrentUserApi = environment.ATTIVEG_BACKEND_HOST + "api/users/current";
    readonly loginApi = environment.ATTIVEG_BACKEND_HOST + "api/login/password";
    readonly logoutApi = environment.ATTIVEG_BACKEND_HOST + "api/logout";
    readonly updateUserApi = environment.ATTIVEG_BACKEND_HOST + "api/users/update";
    readonly sendOptApi = environment.ATTIVEG_BACKEND_HOST + "api/login/send-otp";
    readonly loginOptApi = environment.ATTIVEG_BACKEND_HOST + "api/login/otp";

    //login
    checkStatus = new BehaviorSubject<boolean>(false);
    isUserLoggedIn = this.checkStatus.asObservable();

    constructor(public http: HttpClient) { }

    private currentUser: any;

    getCurrentUser() {
        return this.currentUser;
    }

    setCurrentUser(user: any) {
        this.currentUser = user;
    }

    getLoggedInUser() {
        return this.http.get(this.getCurrentUserApi);
    }

    //login
    checkLogin() {
        const token = localStorage.getItem("access_token");
        if (token) {
            this.checkStatus.next(true);
        } else {
            this.checkStatus.next(false);
        }
    }

    register(data: any) {
        return this.http.post(this.registerApi, data);
    }

    loginUser(user: any) {
        return this.http.post(this.loginApi, user);
    }

    logout() {
        return this.http.get(this.logoutApi);
    }

    updateUser(user: any) {
        return this.http.post(this.updateUserApi, user);
    }

    sendOpt(user: any) {
        return this.http.post(this.sendOptApi, user);
    }

    loginOpt(user: any) {
        return this.http.post(this.loginOptApi, user);
    }
} 