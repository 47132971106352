<div class="mt-6">
    <div class="relative overflow-hidden" [swiper]="config">
        <div class="swiper-wrapper h-full gap-4">      
            <div *ngFor="let slide of slideData.records" class="swiper-slide !mr-0 bg-white max-w-[462px]">
                <div>
                    <mat-card class="mat-card-section !shadow-none !border-0 !bg-transparent !p-0 w-full min-h-16">
                        <a [routerLink]="slide.url" class="image-link">
                            <img [attr.data-src]="slide.image" class="swiper-lazy h-auto w-full object-cover rounded-[50px]" srcset="{{slide.image}} 600w" sizes="100vw"/>
                            <!-- <div class="swiper-lazy-preloader"></div> -->
                        </a>                     
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-prev swipe-arrow"><mat-icon class="text-default">keyboard_arrow_left</mat-icon></button>
    <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-next swipe-arrow"><mat-icon class="text-default">keyboard_arrow_right</mat-icon></button>
</div>