import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { isPlatformBrowser } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit {
  public showBackToTop: boolean = false;
  public categories: Category[];
  public selectedCategory: Category;
  public sidenavMenuItems: Array<any>;
  user: any;
  brands: [];
  searchString: string;
  @ViewChild('sidenav', { static: true }) sidenav: any;

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  private closeTimeout: any;

  searchForm: UntypedFormGroup;
  public settings: Settings;
  @ViewChild('searchMenuTrigger') searchMenuTrigger: MatMenuTrigger;
  // @ViewChild('brandMenuTriggred') menuTrigger: MatMenuTrigger;
  constructor(public formBuilder: UntypedFormBuilder, public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object, private activatedRoute: ActivatedRoute) {
    this.settings = this.appSettings.settings;
  }

  changeOfRoutes(){
    if(!this.router.url.includes('products?s=')) {
      this.searchForm?.controls?.search?.setValue('');
    }
  }

  ngOnInit() {
    this.appService.Data.cartList = JSON.parse(localStorage.getItem('cart') || '[]');
    this.appService.Data.cartList.forEach(product => {
      this.appService.Data.totalPrice = this.appService.Data.totalPrice + (product.cartCount * product.newPrice);
      this.appService.Data.totalCartCount = this.appService.Data.totalCartCount + product.cartCount;
    });
    this.appService.Data.wishList = JSON.parse(localStorage.getItem('wishlist') || '[]');
    this.appService.Data.wishList.forEach(product => {
      this.appService.Data.totalPrice = this.appService.Data.totalPrice + (product.cartCount * product.newPrice);
      this.appService.Data.totalCartCount = this.appService.Data.totalCartCount + product.cartCount;
    });
    this.activatedRoute.parent.data.subscribe((data: any) => {
      this.user = data.user;
    });
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    setTimeout(() => {
      this.settings.theme = 'blue';
    });
    this.searchForm = this.formBuilder.group({
      'search': ['']
    });

    this.getBrands()
  }

  public getCategories() {
    this.activatedRoute.data.subscribe((data: any) => {
      this.categories = data.pages.categories;
      this.selectedCategory = new Category(1, "All Categories", "all", false, null);
      this.categories.unshift(this.selectedCategory);
      this.appService.Data.categories = data.pages.categories;;
    });
  }

  public changeCategory(event) {
    if (event.target) {
      this.selectedCategory = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
    const index: number = this.appService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.appService.Data.cartList.splice(index, 1);
      this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice * product.cartCount;
      this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
      this.appService.resetProductCartCount(product);
      localStorage.setItem('cart',JSON.stringify(this.appService.Data.cartList));
    }
  }

  public clear() {
    this.appService.Data.cartList.forEach(product => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
    localStorage.setItem('cart',JSON.stringify(this.appService.Data.cartList));
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search(event: any) {
    const queryParams: any = {
      s: event.target.search.value
    };
    if(this.selectedCategory?.slug != 'all'){
      queryParams.category = this.selectedCategory?.slug
    }
    if(event.target.search.value) {
      this.searchMenuTrigger.closeMenu();
      this.router.navigate(['/products'], { queryParams: queryParams }).then(() => {
        //window.location.reload();
      });
    }
  }


  public scrollToTop() {
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      }
      else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    let header_toolbar = document.getElementById('header-toolbar');
    if (header_toolbar) {
      if (scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      }
      else {
        if (!document.documentElement.classList.contains('cdk-global-scrollblock')) {
          this.settings.mainToolbarFixed = false;
        }
      }
    }
    else {
      this.settings.mainToolbarFixed = true;
    }
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }
  public getBrands() {
    this.appService.getBrands(null).subscribe((data: any) => {
      this.brands = data.data;
    });
  }

  handleMouseEnter() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
    this.menuTrigger.openMenu();
  }
  
  cancelCloseMenuTimeout() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }
  
  handleMenuLeave() {
    this.closeTimeout = setTimeout(() => {
      if (!this.menuTrigger.menuOpen) return;
      this.menuTrigger.closeMenu();
    }, 300);  // Increased delay
  }
  
  handleMenuEnter() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }
}