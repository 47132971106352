<h1 mat-dialog-title>{{data?.title}}</h1>

<div mat-dialog-content>
    <p>{{data?.message}}</p>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <button (click)="onDismiss()" class="px-4 py-2 bg-gray-100 rounded-lg text-default">No</button>
        <button (click)="onConfirm()" class="px-4 py-2 bg-blue-600 rounded-lg text-white">Yes</button>
    </div>  
</div>