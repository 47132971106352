import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperComponent, SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

  @Input('products') products: Array<Product> = [];
  public settings: Settings;
  @ViewChild(SwiperDirective, { static: false }) swiperDirective: SwiperDirective;
  public config: SwiperConfigInterface = {
    observer: false,
    slidesPerView: 'auto',
    spaceBetween: 16,
    keyboard: {
      enabled: true,
    },
    navigation: true,
    pagination: false,
    grabCursor: true,
    loop: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 10, // Number of slides to load before and after the active slide
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    speed: 500,
    effect: 'slide',
  }
  constructor(public appSettings: AppSettings, public appService: AppService, public dialog: MatDialog, private router: Router) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    // this.updateSwiperConfig?.subscribe(v => {
    //   this.config.observer = v;
    // });
  }

  ngAfterViewInit() {
    if (this.swiperDirective && this.swiperDirective.swiper()) {
      const swiperInstance = this.swiperDirective.swiper();
      swiperInstance.on('slideChangeTransitionStart', () => {
        swiperInstance.autoplay.stop();
        swiperInstance.autoplay.start();
      });
    }
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog',
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.id, product.slug]);
      }
    });
  }
}