<mat-toolbar id="menu-toolbar" class="!bg-white !shadow-md min-h-[50px] justify-center max-w-full overflow-x-auto max-lg:!hidden relative !z-[100]">
    <!-- <a routerLink="/" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.HOME' | translate }}</a> -->
    <div *ngFor="let category of categories">
        <a class="capitalize text-light px-3.5 relative secondary-nav-link" *ngIf="(!category.hasSubCategory && !category.parentId) || category.subCategories?.length == 0" [routerLink]="['/products', category.slug]"
            routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ category.name }}</a>
        
        <a class="capitalize text-light px-3.5 relative secondary-nav-link" #button *ngIf="category.hasSubCategory && !category.parentId && category.subCategories?.length > 0" (mouseenter)="buttonEnter(menuTrigger, category)" (mouseleave)="buttonLeave(menuTrigger, button)" style="z-index:1050" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
        [routerLink]="['/products', category.slug]"
        routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ category.name }}
            
        <mat-menu #menu="matMenu" [overlapTrigger]="false" [hasBackdrop]="false" class="mega-menu app-dropdown popup-shadow mt-6 !rounded !max-w-56 !w-56 p-3">
                <div fxLayout="row wrap" (mouseenter)="menuenter()" (mouseleave)="menuLeave(menuTrigger, button)">
                    <div fxLayout="column" class="p-1">
                        <a class="subcat-items !capitalize text-light hover:text-[#fc2779]" *ngFor="let subCategory of selectedParentCategory.subCategories"
                            [routerLink]="['/products', subCategory.slug]">{{subCategory.name}}</a>                        
                    </div>
                </div>
            </mat-menu>
        </a>
    </div>
    <div>
        <a class="capitalize text-light px-3.5 relative secondary-nav-link" *ngIf="user && user.authorities[0].name == 'ROLE_ADMIN'" routerLink="/admin" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.ADMIN' | translate }}</a>
    </div>
    <!-- static links -->
    <!-- <div>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            makeup
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            hair
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            appliances
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            Bath & Body
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            Natural
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            mom & baby
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            Health & Wellness
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            men
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            fragrance
        </a>
        <a href="#" class="capitalize text-light px-3.5 relative secondary-nav-link">
            Lingerie & Accessories
        </a>
    </div> -->
    <!-- <a mat-button [matMenuTriggerFor]="menu" (click)="openMegaMenu()">Fashion<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">
        <div fxLayout="row wrap">
            <div fxFlex="20" fxLayout="column" class="p-1"> 
                <a mat-menu-item routerLink="/"><b>WOMEN</b></a>
                <mat-divider></mat-divider> 
                <a mat-menu-item routerLink="/">Dresses & Skirts</a>
                <a mat-menu-item routerLink="/">Jackets</a>              
                <a mat-menu-item routerLink="/">Tops & Blouses</a>
                <a mat-menu-item routerLink="/">Shoes & Boots</a>
                <a mat-menu-item routerLink="/">Knitwear</a>                
            </div>
            <div fxFlex="20" class="p-1"> 
                <a mat-menu-item routerLink="/"><b>MEN</b></a>
                <mat-divider></mat-divider> 
                <button mat-menu-item>T-shirts & Polos</button>
                <button mat-menu-item>Shoes & Boots</button> 
                <button mat-menu-item>Jeans</button> 
                <button mat-menu-item>Coats</button> 
            </div>
            <div fxFlex="20" class="p-1"> 
                <a mat-menu-item routerLink="/"><b>KIDS</b></a>
                <mat-divider></mat-divider> 
                <button mat-menu-item>Top</button>
                <button mat-menu-item>Pants & Shorts</button>
                <button mat-menu-item>Dresses</button> 
                <button mat-menu-item>Skirts</button> 
                <button mat-menu-item>Set & Body</button> 
            </div>
            <div fxFlex="20" class="p-1"> 
                <a mat-menu-item routerLink="/"><b>ACCESSORIES</b></a>
                <mat-divider></mat-divider>                
                <button mat-menu-item>Watches</button>  
                <button mat-menu-item>Bags & Wallet</button>
                <button mat-menu-item>Sunglasses</button> 
                <button mat-menu-item>Belts & Hats</button> 
            </div>
            <div fxFlex="20" class="p-1"> 
                <mat-card class="mega-menu-widget p-0">
                    <mat-chip-list>
                        <mat-chip color="warn" selected="true">50% OFF</mat-chip>
                    </mat-chip-list>
                    <button mat-raised-button color="primary">Shop now</button>
                    <img src="assets/images/others/mega_menu_img.png" alt="" class="w-100"> 
                </mat-card>
            </div>
        </div>
           
    </mat-menu>
    
    <a mat-button [routerLink]="['/products', 'jewellery']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Jewellery</a>
    <a mat-button [routerLink]="['/products', 'electronics']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Electronics</a>
    <a mat-button [routerLink]="['/products', 'sports']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Sports</a> 

    <a mat-button [matMenuTriggerFor]="pages">{{ 'NAV.PAGES' | translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #pages="matMenu" [overlapTrigger]="false" class="app-dropdown">  
        <a mat-menu-item routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CONTACT' | translate }}</a> 
        <a mat-menu-item [matMenuTriggerFor]="shop">{{ 'NAV.SHOP' | translate }}</a>
        <a mat-menu-item routerLink="/sign-in">{{ 'LOGIN' | translate }}</a>       
        <a mat-menu-item routerLink="/404">{{ 'NAV.404_PAGE' | translate }}</a>
        <a mat-menu-item routerLink="/landing">{{ 'NAV.LANDING' | translate }}</a>
        <a mat-menu-item [matMenuTriggerFor]="others">{{ 'NAV.OTHERS' | translate }}</a>
    </mat-menu>

    <mat-menu #shop="matMenu" class="app-dropdown"> 
        <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.ALL_PRODUCTS' | translate }}</a> 
        <a mat-menu-item [routerLink]="['/products', '2', 'PC All-in-One']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.PRODUCT_DETAIL' | translate }}</a> 
        <a mat-menu-item routerLink="/cart" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CART' | translate }}</a>
        <a mat-menu-item routerLink="/checkout" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CHECKOUT' | translate }}</a>
    </mat-menu>

    <mat-menu #others="matMenu" class="app-dropdown">
        <a mat-menu-item href="http://themeseason.com" target="_blank">{{ 'NAV.EXTERNAL_LINK' | translate}}</a>
        <a mat-menu-item>Menu item</a>
        <a mat-menu-item>Menu item</a>
        <a mat-menu-item>Menu item</a>
    </mat-menu>
    
    <a mat-button routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CONTACT' | translate }}</a> -->
    

</mat-toolbar>

