<div fxLayout="row" fxLayout.xs="column" class="justify-start text-light gap-4 action-parent-area">
    <div *ngIf="product?.availabilityCount > 0 && (type=='all' || type=='wish')">
        <!-- <bdi>
            <span *ngIf="type!='wish'" class="fw-500">Quantity:</span> 
            <button mat-icon-button matTooltip="Remove" (click)="decrement()"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="Add" (click)="increment()"><mat-icon>add</mat-icon></button>
        </bdi> -->
        <button type="button" class="bg-white flex items-center px-3 py-1.5 gap-3 w-max rounded product-action-button">
            <mat-icon matTooltip="Remove" (click)="decrement()" class="text-[16px] !w-4 !h-4 hover:text-red-600">remove</mat-icon>
            <span>Qty: {{count}}</span>
            <mat-icon matTooltip="Add" (click)="increment()" class="text-[16px] !w-4 !h-4 hover:text-blue-600">add</mat-icon>
        </button>
    </div>
    <div *ngIf="type!='wish'" class="action-btn-section flex items-center justify-between flex-wrap max-sm:items-start gap-4 w-100 relative">
        <div class="flex sm:items-center gap-2 max-sm:flex-col cart-btn-section">
            <button matTooltip="Add to cart" [disabled]="!(product?.availabilityCount > 0)" (click)="addToCart(product)" class="bg-blue-600 px-2 py-1 text-[13px] text-white rounded flex items-center gap-2 product-action-button add-to-cart-btn">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-white cart-icon hidden" viewBox="0 0 512 430">
                    <path d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0" data-original="#000000"></path>
                </svg>
                Add to cart
            </button>
            <button matTooltip="Buy now" [disabled]="!(product?.availabilityCount > 0)" (click)="buyNow(product)" class="theme-bg-color px-2 py-1 text-[13px] text-white rounded flex items-center gap-2 product-action-button buy-btn">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-white cart-icon hidden" viewBox="0 0 32 32">
                    <path d="M21.65 10.347a1.13 1.13 0 0 0-1.13 1.13c0 2.492-2.027 4.52-4.52 4.52s-4.52-2.028-4.52-4.52a1.13 1.13 0 0 0-2.26 0c0 3.739 3.04 6.78 6.78 6.78s6.78-3.041 6.78-6.78a1.13 1.13 0 0 0-1.13-1.13z" data-original="#000000"/>
                    <path d="M30.98 25.286 28.353 8.691a3.374 3.374 0 0 0-3.348-2.861h-2.328C22.136 2.629 19.353.18 16 .18S9.863 2.629 9.322 5.83H6.994a3.374 3.374 0 0 0-3.348 2.861L1.02 25.286a5.644 5.644 0 0 0 1.285 4.554 5.642 5.642 0 0 0 4.296 1.98h18.796c1.656 0 3.22-.722 4.296-1.98a5.644 5.644 0 0 0 1.285-4.554zM16 2.44c2.1 0 3.853 1.446 4.36 3.39h-8.72c.506-1.944 2.26-3.39 4.36-3.39zm11.976 25.931a3.385 3.385 0 0 1-2.578 1.189H6.602a3.383 3.383 0 0 1-2.578-1.189 3.384 3.384 0 0 1-.772-2.731L5.877 9.044a1.124 1.124 0 0 1 1.117-.954h18.012c.56 0 1.028.401 1.117.954l2.625 16.596a3.384 3.384 0 0 1-.772 2.731z" data-original="#000000"/>
                  </svg>
                Buy now
            </button>
        </div>
        <!-- <button *ngIf="type!='all'" matTooltip="Quick view" (click)="openProductDialog(product)">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline fill-[#fc2779]" viewBox="0 0 511.999 511.999">
                <path d="M508.745 246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818 239.784 3.249 246.035a16.896 16.896 0 0 0 0 19.923c4.569 6.257 113.557 153.206 252.748 153.206s248.174-146.95 252.748-153.201a16.875 16.875 0 0 0 0-19.922zM255.997 385.406c-102.529 0-191.33-97.533-217.617-129.418 26.253-31.913 114.868-129.395 217.617-129.395 102.524 0 191.319 97.516 217.617 129.418-26.253 31.912-114.868 129.395-217.617 129.395z" data-original="#000000"/>
                <path d="M255.997 154.725c-55.842 0-101.275 45.433-101.275 101.275s45.433 101.275 101.275 101.275S357.272 311.842 357.272 256s-45.433-101.275-101.275-101.275zm0 168.791c-37.23 0-67.516-30.287-67.516-67.516s30.287-67.516 67.516-67.516 67.516 30.287 67.516 67.516-30.286 67.516-67.516 67.516z" data-original="#000000"/>
              </svg>
        </button> -->
        <button matTooltip="Add to wishlist" [disabled]="isInwishList(product?.id)" (click)="addToWishList(product)" class="add-to-wish-btn relative !opacity-100">
            <img *ngIf="isAnimate" src="assets/images/heart-animation.gif" class="absolute w-full h-full object-none m-0 overflow-visible heart-animate-img"/>
            <svg *ngIf="isInwishList(product?.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-5 h-5 inline fill-[#fc2779]">
                <path d="M471.383 74.578C444.879 45.832 408.512 30 368.973 30c-29.555 0-56.621 9.344-80.45 27.77C276.5 67.07 265.605 78.45 256 91.73c-9.602-13.277-20.5-24.66-32.527-33.96C199.648 39.344 172.582 30 143.027 30c-39.539 0-75.91 15.832-102.414 44.578C14.426 102.988 0 141.801 0 183.871c0 43.3 16.137 82.938 50.781 124.742 30.992 37.395 75.535 75.356 127.117 119.313 17.614 15.012 37.579 32.027 58.309 50.152A30.023 30.023 0 0 0 256 485.516a30.03 30.03 0 0 0 19.785-7.43c20.73-18.129 40.707-35.152 58.328-50.172 51.575-43.95 96.117-81.906 127.11-119.305C495.867 266.81 512 227.172 512 183.867c0-42.066-14.426-80.879-40.617-109.289zm0 0" data-original="#000000"/>
              </svg>
            <svg *ngIf="!isInwishList(product?.id)" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline fill-[#fc2779]" viewBox="0 0 512 460">
                <path d="M256 455.516a30.022 30.022 0 0 1-19.793-7.438c-20.684-18.086-40.625-35.082-58.219-50.074l-.09-.078c-51.582-43.957-96.125-81.918-127.117-119.313C16.137 236.81 0 197.172 0 153.871c0-42.07 14.426-80.883 40.617-109.293C67.121 15.832 103.488 0 143.031 0c29.555 0 56.621 9.344 80.446 27.77C235.5 37.07 246.398 48.453 256 61.73c9.605-13.277 20.5-24.66 32.527-33.96C312.352 9.344 339.418 0 368.973 0c39.539 0 75.91 15.832 102.414 44.578C497.578 72.988 512 111.801 512 153.871c0 43.3-16.133 82.938-50.777 124.738-30.993 37.399-75.532 75.356-127.106 119.309-17.625 15.016-37.597 32.039-58.328 50.168a30.046 30.046 0 0 1-19.789 7.43zM143.031 29.992c-31.066 0-59.605 12.399-80.367 34.914-21.07 22.856-32.676 54.45-32.676 88.965 0 36.418 13.535 68.988 43.883 105.606 29.332 35.394 72.961 72.574 123.477 115.625l.093.078c17.66 15.05 37.68 32.113 58.516 50.332 20.961-18.254 41.012-35.344 58.707-50.418 50.512-43.051 94.137-80.223 123.469-115.617 30.344-36.618 43.879-69.188 43.879-105.606 0-34.516-11.606-66.11-32.676-88.965-20.758-22.515-49.3-34.914-80.363-34.914-22.758 0-43.653 7.235-62.102 21.5-16.441 12.719-27.894 28.797-34.61 40.047-3.452 5.785-9.53 9.238-16.261 9.238s-12.809-3.453-16.262-9.238c-6.71-11.25-18.164-27.328-34.61-40.047-18.448-14.265-39.343-21.5-62.097-21.5zm0 0" data-original="#000000"/>
               </svg>
        </button>
    </div>
</div> 
<div *ngIf="product?.availabilityCount == 0" class="bg-warn py-0.5 px-2 rounded absolute -top-4 right-2 not-available">
    <span class="text-[13px]">Not available</span>
</div>