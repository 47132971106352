// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ATTIVEG_BACKEND_HOST: 'https://attiveg.com:8443/',
  //ATTIVEG_BACKEND_HOST: 'http://localhost:8080/',
  url: '',
  CCAVENUE_URL: 'https://secure.ccavenue.com',
  CCAVENUE_ACCESS_CODE: 'AVVC63KE43AS46CVSA'
  //CCAVENUE_ACCESS_CODE: 'AVIW63KE82AS62WISA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
