<div class="brands-carousel px-12 max-sm:px-6 mt-12 bg-white" *ngIf="brands.length > 0">
    <h2>Top Brands</h2>
    <div class="relative">
        <div class="relative h-100 mt-6 py-2 brands-carousel-section rounded-lg overflow-hidden" [swiper]="config">
            <div class="swiper-wrapper items-center w-full gap-12 h-100">
                <div *ngFor="let brand of brands" class="swiper-slide !mr-0 !w-max">
                    <div style="border-radius: 25px;" class="p-0">
                        <a [routerLink]="['/brands', brand.key]" class="brand-item block w-[100px] h-[100px]">
                            <img [attr.data-src]="brand.image" class="swiper-lazy w-full h-full object-contain">
                        </a>
                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
            </div>
        </div>
        <button
            class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-prev swipe-arrow"><mat-icon
                class="text-default">keyboard_arrow_left</mat-icon></button>
        <button
            class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-next swipe-arrow"><mat-icon
                class="text-default">keyboard_arrow_right</mat-icon></button>
    </div>
</div>