<div class="!p-0 relative mt-6" [swiper]="config">
    <div class="swiper-wrapper h-100 gap-4">      
        <div *ngFor="let product of products" class="swiper-slide !mr-0 bg-purple-100 p-2 rounded-lg !w-max">
            <div>
                <div class="rating-offer-cont !absolute top-0 w-full z-50">
                    <!-- <app-rating [ratingsCount]="product.ratingsCount" [ratingsValue]="product.ratingsValue" [direction]="'column'"></app-rating> -->
                    <!-- <mat-chip *ngIf="product.ratingsCount>0" class="rating" color="primary" selected="true">⭐{{product.ratingsCount}}</mat-chip> -->
                    <mat-chip-list *ngIf="product.discount" >
                        <!-- <mat-chip color="primary" class="offer" selected="true">{{product.discount}}% OFF</mat-chip> -->
                        <span class="theme-bg-color px-2 py-0.5 text-[13px] text-white rounded absolute -top-4 left-0">{{product.discount}}% OFF</span>
                    </mat-chip-list>
                </div>
                <mat-card class="mat-card-section !shadow-none !border-0 !bg-transparent !p-0 w-[207px] h-[207px]">
                    <a [routerLink]="['/products', product.id, product.slug]" class="image-link">
                        <img [attr.data-src]="product?.images[0]?.medium" class="swiper-lazy h-100 w-full object-cover rounded-lg"/>
                        <div class="swiper-lazy-preloader"></div>
                    </a>                        
                    <!-- <div class="divider mt-2"></div> -->
                    
                </mat-card>
                <div class="max-w-[207px] product-content-sec">
                <div class="mt-3">
                    <h6 class="!text-sm mb-2 text-truncate">{{ ( appService.Data.categories | filterById : product.categoryId )?.name }}</h6>                                                                     
                    <!-- <a [routerLink]="['/products', product.id, product.slug]" class="title text-truncate">
                        {{product.name}}
                    </a> -->
                    <div class="flex items-center !gap-2 font-semibold">
                        <p class="old-price text-light"><span *ngIf="product.oldPrice">₹{{product.oldPrice | number : '1.2-2'}}</span></p>
                        <p class="theme-color">₹{{product.newPrice | number : '1.2-2'}}</p>
                    </div>
                    <!-- <app-rating [ratingsCount]="product.ratingsCount" [ratingsValue]="product.ratingsValue" [direction]="'column'"></app-rating> -->
                </div>    

                <div class="icons mt-3">
                    <h6 class="product-name">
                        <a [routerLink]="['/products', product.id, product.slug]" class="text-sm font-semibold text-truncate block">
                            {{product.name.length>=25?product.name.slice(0,20)+"...":product.name}}
                        </a>
                    </h6>
                    <div class="mt-2">
                        <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-prev swipe-arrow"><mat-icon class="text-default">keyboard_arrow_left</mat-icon></button>
    <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-next swipe-arrow"><mat-icon class="text-default">keyboard_arrow_right</mat-icon></button>
</div>