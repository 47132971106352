import { Injectable } from "@angular/core";
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { AppService } from "../app.service";

@Injectable({
    providedIn: "root",
})
export class HomeResolverService implements Resolve<any> {
    isLoggedIn: boolean = false;

    constructor(
        private appService: AppService    ) { }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        let slides = this.appService.getAllSlides(null).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    localStorage.removeItem("access_token");
                    location.reload();
                    return throwError(() => err);
                }
            })
        );
        let sections = this.appService.getAllSections(null).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    localStorage.removeItem("access_token");
                    location.reload();
                    return throwError(() => err);
                }
            })
        );
        let join = forkJoin([slides, sections]).pipe(map((allResponses: any) => {
            return {
                slides: allResponses[0].data,
                sections: allResponses[1].data
            };
        }))
        return join;
    }
}
