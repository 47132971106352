import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  isLoggedIn: boolean = false;
  constructor(private spinner: NgxSpinnerService, private auth: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinner.show();
    if (req.url.includes(environment.ATTIVEG_BACKEND_HOST)) {
      if (localStorage.getItem("access_token")) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
      }
    }

    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
      return event;
    }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            localStorage.removeItem("access_token");
            this.router.navigate(['/']);
          }
          this.spinner.hide();
          return throwError(() => error);
        }
      })
    )
  }
  logout() {
    this.auth.logout().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          localStorage.removeItem("access_token");
          this.auth.checkLogin();
          this.auth.isUserLoggedIn.subscribe((val) => {
            this.isLoggedIn = val;
          });
          this.router.navigate([""]);
        }
      },
      error: (err: any) => {
        this.router.navigate([""]);
      }
    });
  }
}