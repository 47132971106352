import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { SeoService } from './services/seo.service';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  public user: any;
  constructor(public appSettings: AppSettings,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService, private activeRoute: ActivatedRoute,
    private auth: AuthService,
    private _seoService: SeoService, private titleService: Title) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'de', 'fr', 'ru', 'tr']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.titleService.setTitle(event["title"]);
        //this._seoService.updateTitle(event["title"]);
        this._seoService.updateDescription(event["description"]);
        this._seoService.updateKeywords(event["keywords"]);
        this._seoService.updateOgTitle(event["ogTitle"]);
        this._seoService.updateOgDesc(event["ogDesc"]);
        this._seoService.updateOgImg(event["ogImage"]);
        this._seoService.updateOgUrl(event["ogUrl"]);
        this._seoService.updateTwitterTitle(event["twitterTitle"]);
        this._seoService.updateTwitterDesc(event["twitterDesc"]);
        this._seoService.updateTwitterImg(event["twitterImage"]);
      });
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
    this.activeRoute.data.subscribe((data: any) => {
      this.user = data.user;
      if (null != this.user) {
        this.auth.setCurrentUser(this.user);
      }
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    })
  }
}
