import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserResolverService implements Resolve<any> {
  isLoggedIn: boolean = false;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let url = state.url;
    if (localStorage.getItem("access_token")) {
      return this.auth.getLoggedInUser().pipe(
        catchError((err) => {
          if (err.status === 401) {
            localStorage.removeItem("access_token");
            location.reload();
            return throwError(() => err);
          }
        })
      );
    }
    else if (url.includes("/admin")){
      this.router.navigate(['/']);
    }
  }
}
