import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(private title: Title, private meta: Meta) { }

    updateTitle(title: string) {
        this.title.setTitle(title);
    }

    updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc });
    }

    updateKeywords(ky: string) {
        this.meta.updateTag({ name: 'keywords', content: ky });
    }

    updateOgTitle(ogTitle: string) {
        this.meta.updateTag({ property: "og:title", content: ogTitle });
    }

    updateOgDesc(ogDesc: string) {
        this.meta.updateTag({ property: "og:description", content: ogDesc });
    }

    updateOgImg(ogImg: string) {
        this.meta.updateTag({ property: "og:image", content: ogImg });
    }

    updateOgUrl(ogUrl: string) {
        this.meta.updateTag({ property: "og:url", content: ogUrl });
    }

    updateTwitterTitle(twitterTitle: string) {
        this.meta.updateTag({ name: "twitter:title", content: twitterTitle });
    }

    updateTwitterDesc(twitterDesc: string) {
        this.meta.updateTag({ name: "twitter:description", content: twitterDesc });
    }

    updateTwitterImg(twitterImg: string) {
        this.meta.updateTag({ name: "twitter:image", content: twitterImg });
    }

}