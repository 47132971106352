<!-- <div class="content">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxLayoutAlign="space-around stretch" [fxFlex]="(100/4) + '%'" fxFlex.xs="100%" fxFlex.sm="20%"
      *ngFor="let banner of banners">
      <a [routerLink]="['/products', banner.slug]" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">
      <mat-card class="mat-elevation-z4">
        <mat-card-header>
          <mat-card-title>{{banner.title}}</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="{{banner.image}}">
        <mat-card-content fxLayoutAlign="center">
          <p>
            <strong>
              {{banner.name}}
            </strong>
          </p>
        </mat-card-content>
        <mat-card-content fxLayoutAlign="center">
          <p>
            {{banner.productCount}} products 
          </p>
        </mat-card-content> -->
        <!-- <mat-card-actions>
            <button mat-button>Shop now</button>
          </mat-card-actions> -->
      <!-- </mat-card>
      </a>
    </div>
  </div>
</div> -->

<!-- Implemented slider in the below code revert to code above in case of issues-->
  <div class="relative mt-6" [swiper]="config">
    <div class="swiper-wrapper h-100 gap-4">  
      <div *ngFor="let banner of banners" class="bg-white p-2 rounded-lg">
        <a [routerLink]="['/products', banner.slug]" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">
        <mat-card class="!shadow-none !border-0 !bg-transparent !p-0 w-[207px] h-[207px] mat-card-section">
          <!-- <mat-card-header>
            <mat-card-title>{{banner.title}}</mat-card-title>
          </mat-card-header> -->
          <img src="{{banner.image}}" class="h-100 w-full object-cover rounded-lg">
          <!-- <mat-card-content fxLayoutAlign="center"> -->
            <!-- <p>
              <strong>
                {{banner.name}}
              </strong>
            </p> -->
          <!-- </mat-card-content> -->
          <!-- <mat-card-content fxLayoutAlign="center"> -->
            <!-- <p>
              {{banner.productCount}} products 
            </p> -->
          <!-- </mat-card-content> -->
          <!-- <mat-card-actions>
              <button mat-button>Shop now</button>
            </mat-card-actions> -->
        </mat-card>
        <div class="banner-footer items-center !gap-2 mt-2 font-semibold max-w-[207px] product-content-sec">
            <h6 class="!text-sm text-truncate">
              {{banner.name}}
            </h6>
          <p>
            ({{banner.productCount}})
          </p>
        </div>
        </a>

      </div>
      
    </div>
    <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-prev swipe-arrow"><mat-icon class="text-default">keyboard_arrow_left</mat-icon></button>
    <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 bg-white border-2 border-c rounded-full swiper-button-next swipe-arrow"><mat-icon class="text-default">keyboard_arrow_right</mat-icon></button>
  </div>
