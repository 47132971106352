<footer class="bg-gray-200 px-12 max-sm:px-6 pt-12 pb-6">
    <div class="flex flex-wrap gap-6 justify-between">        
        <div> 
            <h6 class="col-title mb-4">Useful Links</h6>
            <p class="mt-4"><a routerLink="/account/dashboard" class="text-light hover:text-[#fc2779] transition-all">My Account</a></p>
            <p class="mt-4"><a routerLink="/wishlist" class="text-light hover:text-[#fc2779] transition-all">Wishlist</a></p>
            <p class="mt-4"><a routerLink="/cart" class="text-light hover:text-[#fc2779] transition-all">Cart</a></p>
        </div>
        <div> 
            <h6 class="col-title mb-4">About Us</h6>
            <p class="mt-4"><a routerLink="/" class="text-light hover:text-[#fc2779] transition-all">About Us</a></p>
            <p class="mt-4"><a routerLink="/contact" class="text-light hover:text-[#fc2779] transition-all">Contact Us</a></p>
            <p class="mt-4"><a routerLink="/" class="text-light hover:text-[#fc2779] transition-all">FAQs</a></p>
        </div>
        <div> 
            <h6 class="col-title mb-4">Legal</h6>
            <p class="mt-4"><a routerLink="/" class="text-light hover:text-[#fc2779] transition-all">Terms of Service</a></p>
            <p class="mt-4"><a routerLink="/" class="text-light hover:text-[#fc2779] transition-all">Privacy Policy</a></p>
            <p class="mt-4"><a routerLink="/" class="text-light hover:text-[#fc2779] transition-all">Shipping & Refunds</a></p>
        </div>
        
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-4" class="contact-information"> 
            <h6 class="col-title mb-4">CONTACT INFORMATION</h6>            
            <p fxLayout="row" fxLayoutAlign="start center" class="gap-4 mt-4 text-light hover:text-[#fc2779] transition-all">
                <mat-icon>location_on</mat-icon>
                <span>1307, Arjun Nagar, Rohtak, Haryana</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="gap-3 mt-4 text-light hover:text-[#fc2779] transition-all">
                <mat-icon>call</mat-icon>
                <span>+91 95883 79736</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="gap-3 mt-4 text-light hover:text-[#fc2779] transition-all">
                <mat-icon>mail_outline</mat-icon>
                <span>menterprises084@gmail.com</span>
            </p>
            <!-- <p fxLayout="row" fxLayoutAlign="start center" class="gap-3 mt-4 text-light hover:text-[#fc2779] transition-all">
                <mat-icon>schedule</mat-icon>
                <span>Mon - Sun / 9:00AM - 8:00PM</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="gap-3 mt-4 text-light hover:text-[#fc2779] transition-all">
                <mat-icon>directions</mat-icon>
                <a href="javascript:void(0);" class="text-light hover:text-[#fc2779] transition-all">Get directions</a>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="gap-3 mt-4 text-light hover:text-[#fc2779] transition-all">
                <mat-icon>directions_bus</mat-icon>
                <span>Routes to us</span>
            </p> -->

            <div class="mt-6">
                <h6 class="flex items-center flex-wrap gap-1">Show us some love 
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 512.001 512.001">
                        <path d="M256.001 477.407c-2.59 0-5.179-.669-7.499-2.009-2.52-1.454-62.391-36.216-123.121-88.594-35.994-31.043-64.726-61.833-85.396-91.513C13.237 256.885-.214 219.943.003 185.49c.254-40.09 14.613-77.792 40.435-106.162 26.258-28.848 61.3-44.734 98.673-44.734 47.897 0 91.688 26.83 116.891 69.332 25.203-42.501 68.994-69.332 116.891-69.332 35.308 0 68.995 14.334 94.859 40.362 28.384 28.563 44.511 68.921 44.247 110.724-.218 34.393-13.921 71.279-40.728 109.632-20.734 29.665-49.426 60.441-85.279 91.475-60.508 52.373-119.949 87.134-122.45 88.588a15.008 15.008 0 0 1-7.541 2.032z" data-original="#000000"/>
                      </svg>
                    on social media</h6>
                <div class="mt-4 flex items-center flex-wrap gap-5">
                    <a href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 512 512">
                            <path d="M305 256c0 27.063-21.938 49-49 49s-49-21.938-49-49 21.938-49 49-49 49 21.938 49 49zm0 0" data-original="#000000"/>
                            <path d="M370.594 169.305a45.546 45.546 0 0 0-10.996-16.903 45.514 45.514 0 0 0-16.903-10.996c-5.18-2.011-12.96-4.406-27.293-5.058-15.504-.707-20.152-.86-59.402-.86-39.254 0-43.902.149-59.402.856-14.332.656-22.118 3.05-27.293 5.062a45.483 45.483 0 0 0-16.903 10.996 45.572 45.572 0 0 0-11 16.903c-2.011 5.18-4.406 12.965-5.058 27.297-.707 15.5-.86 20.148-.86 59.402 0 39.25.153 43.898.86 59.402.652 14.332 3.047 22.114 5.058 27.293a45.563 45.563 0 0 0 10.996 16.903 45.514 45.514 0 0 0 16.903 10.996c5.18 2.015 12.965 4.41 27.297 5.062 15.5.707 20.144.856 59.398.856 39.258 0 43.906-.149 59.402-.856 14.332-.652 22.118-3.047 27.297-5.062a48.68 48.68 0 0 0 27.899-27.899c2.011-5.18 4.406-12.96 5.062-27.293.707-15.504.856-20.152.856-59.402 0-39.254-.149-43.902-.856-59.402-.652-14.332-3.047-22.118-5.062-27.297zM256 331.485c-41.691 0-75.488-33.794-75.488-75.485s33.797-75.484 75.488-75.484c41.688 0 75.484 33.793 75.484 75.484S297.688 331.484 256 331.484zm78.469-136.313c-9.742 0-17.64-7.899-17.64-17.64s7.898-17.641 17.64-17.641 17.64 7.898 17.64 17.64c-.004 9.742-7.898 17.64-17.64 17.64zm0 0" data-original="#000000"/>
                            <path d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm146.113 316.605c-.71 15.649-3.199 26.333-6.832 35.684a75.164 75.164 0 0 1-42.992 42.992c-9.348 3.633-20.035 6.117-35.68 6.832-15.675.715-20.683.887-60.605.887-39.926 0-44.93-.172-60.61-.887-15.644-.715-26.331-3.199-35.68-6.832a72.018 72.018 0 0 1-26.038-16.957 72.044 72.044 0 0 1-16.953-26.035c-3.633-9.348-6.121-20.035-6.832-35.68-.723-15.68-.891-20.687-.891-60.609s.168-44.93.887-60.605c.71-15.649 3.195-26.332 6.828-35.684a72.013 72.013 0 0 1 16.96-26.035 72.003 72.003 0 0 1 26.036-16.957c9.352-3.633 20.035-6.117 35.684-6.832C211.07 109.172 216.078 109 256 109s44.93.172 60.605.89c15.649.712 26.332 3.196 35.684 6.825a72.061 72.061 0 0 1 26.04 16.96 72.027 72.027 0 0 1 16.952 26.036c3.637 9.352 6.121 20.035 6.836 35.684.715 15.675.883 20.683.883 60.605s-.168 44.93-.887 60.605zm0 0" data-original="#000000"/>
                        </svg>
                    </a>
                    <a href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 512 512">
                            <path fill-rule="evenodd" d="M255.182 2.802q70.615.806 127.588 34.71a254.729 254.729 0 0 1 90.667 91.223q33.698 57.315 34.502 128.36-2.001 97.206-61.32 166.046c-39.545 45.895-90.193 74.287-142.623 85.171V326.623h49.57l11.209-71.4h-75.059v-46.765a40.64 40.64 0 0 1 8.644-26.849q9.003-11.478 31.711-12.066h45.325V107q-.976-.314-18.513-2.483a367.958 367.958 0 0 0-39.943-2.482q-45.322.209-71.687 25.57-26.367 25.351-26.937 73.357v54.262h-57.12v71.4h57.12v181.69c-64.377-10.883-115.025-39.276-154.57-85.17S3.76 321.899 2.426 257.095q.8-71.048 34.5-128.36a254.734 254.734 0 0 1 90.67-91.223Q184.566 3.614 255.181 2.8z" data-original="#000000"/>
                          </svg>
                    </a>
                    <a href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 1227 1227">
                            <path d="M613.5 0C274.685 0 0 274.685 0 613.5S274.685 1227 613.5 1227 1227 952.315 1227 613.5 952.315 0 613.5 0z" data-original="#000000"/>
                            <path fill="#fff" d="m680.617 557.98 262.632-305.288h-62.235L652.97 517.77 470.833 252.692H260.759l275.427 400.844-275.427 320.142h62.239l240.82-279.931 192.35 279.931h210.074L680.601 557.98zM345.423 299.545h95.595l440.024 629.411h-95.595z" data-original="#ffffff"/>
                          </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center gap-3" class="mt-6 pt-6 border-t border-gray-300">
        <p class="text-light">Copyright © 2023 All Rights Reserved</p>
        <p class="text-light">Designed & Developed by <a href="https://attiveg.com/" target="_blank" class="theme-color">Attiveg</a></p>
    </div>
</footer>