

<mat-sidenav-container class="!bg-white">



    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider !mb-4"></div>
        <app-sidenav-menu></app-sidenav-menu>
        <!-- <div class="divider"></div> -->
        <!-- <div class="text-center py-2">
            <svg class="social-icon" viewBox="0 0 24 24">
                <path
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z" />
            </svg>
        </div> -->
    </mat-sidenav>

    
    <div class="theme-bg-color min-h-10 flex flex-wrap items-center gap-4 max-sm:gap-2 px-12 py-1 max-sm:px-2">
        <span class="capitalize text-white">online support 24/7</span>
        <div class="border-r border-white h-5"></div>
        <span class="contact-cont text-white flex items-center gap-2">
            <mat-icon class="icon-phone">settings_phone</mat-icon>
            Call us: +91 9588379736 
        </span>
        <!-- <span class="example-spacer"></span> -->
        <span class="text-white max-lg:hidden">Free shipping on all orders over ₹ 99</span>
        <div class="border-r border-white h-5 max-lg:hidden"></div>
        <span class="capitalize text-white max-lg:hidden">shop now</span>
      </div>

    <div id="header-toolbar" class="py-2 border-b border-c">

        <!-- <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button> 
            </span>
            <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center"><mat-icon class="mat-icon-sm">call</mat-icon>  Hotline: (+100) 123 456 7890 </span>
            <span fxShow="false" fxShow.gt-sm>{{ 'WELCOME' | translate }}</span>
            <app-top-menu></app-top-menu>       
        </mat-toolbar-row> -->
        <div class="flex items-center justify-between flex-wrap gap-6 max-sm:gap-4 w-100 logo-toolbar px-12 max-sm:px-4">
            <div class="flex items-center flex-wrap gap-7 max-sm:gap-4">
                <span class="hidden max-lg:block">
                    <button (click)="sidenav.toggle()" class="sidenav-toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline" viewBox="0 0 64 64">
                            <path d="M59.3 17.7H4.7c-2.21 0-3.9-1.69-3.9-3.9s1.69-3.9 3.9-3.9h54.6c2.21 0 3.9 1.69 3.9 3.9s-1.69 3.9-3.9 3.9zm0 18.2H4.7C2.49 35.9.8 34.21.8 32s1.69-3.9 3.9-3.9h54.6c2.21 0 3.9 1.69 3.9 3.9s-1.69 3.9-3.9 3.9zm0 18.2H4.7c-2.21 0-3.9-1.69-3.9-3.9s1.69-3.9 3.9-3.9h54.6c2.21 0 3.9 1.69 3.9 3.9s-1.69 3.9-3.9 3.9z" data-original="#000000"/>
                          </svg>
                    </button> 
                </span>
                <div class="w-[120px] max-sm:w-[84px]">
                    <a class="logo" routerLink="/" (click)="closeSubMenus()"><img src="assets/images/attiveg-text-logo.png" alt="image"></a>
                </div>
                <!-- static links -->
                    <div class="flex items-center gap-7 flex-wrap max-lg:!hidden">
                        <div>
                            <a class="capitalize hover:text-[#fc2779] font-semibold block" routerLink="/">categories</a>
                        </div>

                        <!-- <div (mouseleave)="handleMouseLeave()"> -->
                        <div>
                                <a class="capitalize hover:text-[#fc2779] font-semibold block"
                                   routerLink="/"
                                   (mouseenter)="handleMouseEnter()"
                                   (mouseleave)="handleMenuLeave()"
                                   #menuTrigger="matMenuTrigger"
                                   [matMenuTriggerFor]="menu">
                                  Brands
                                </a>
                              
                                <mat-menu #menu="matMenu"
                                          (mouseleave)="handleMenuLeave()"
                                          (mouseenter)="handleMenuEnter()"
                                          [overlapTrigger]="false"
                                          [hasBackdrop]="false"
                                          class="!bg-purple-100 mega-menu app-dropdown top-mega-menu mt-[20px] !rounded !w-[1070px] !max-w-full !p-0">
                                  <div class="flex items-start"
                                       (mouseenter)="cancelCloseMenuTimeout()"
                                       (mouseleave)="handleMenuLeave()">
                                    <div class="bg-white p-6 max-w-[300px] w-full space-y-2">
                                      <h4 class="uppercase font-semibold !mb-4">topbrands</h4>
                                      <div *ngFor="let brand of brands" class="space-y-2">
                                        <div>
                                          <a [routerLink]="['/brands', brand.key]" class="!capitalize text-light hover:text-[#fc2779]">
                                            {{ brand.name }}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="flex items-center justify-center flex-wrap gap-8 p-8 max-w-[70%] mx-auto w-full">
                                      <div *ngFor="let brand of brands">
                                        <a [routerLink]="['/brands', brand.key]">
                                          <img src="{{brand.image}}" class="w-24"/>
                                        </a>
                                      </div>
                                  </div>
                              </div>
                            </mat-menu>
                        </div>
                          
                          
                          
                        <!-- <div>
                            <a class="capitalize hover:text-[#fc2779] font-semibold block"
                               routerLink="/"
                               [matMenuTriggerFor]="menu">
                              Brands
                            </a>
                            
                            <mat-menu #menu="matMenu" 
                                      [overlapTrigger]="false" 
                                      [hasBackdrop]="false" 
                                      class="mega-menu app-dropdown popup-shadow mt-6 !rounded !max-w-56 !w-56 p-3">
                              <div class="flex">
                                <div class="p-2">
                                  <div *ngFor="let brand of brands" class="swiper-slide !mr-0 !w-max">
                                    <div>
                                      <a [routerLink]="['/brands', brand.key]" class="">
                                        {{brand.name}}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-menu>
                          </div> -->
                          
                        <div>
                            <a class="capitalize hover:text-[#fc2779] font-semibold block" routerLink="/">Luxe</a>
                        </div>
                        <div>
                            <a class="capitalize hover:text-[#fc2779] font-semibold block" routerLink="/">Attiveg Fashion</a>
                        </div>
                        <div>
                            <a class="capitalize hover:text-[#fc2779] font-semibold block" routerLink="/">Beauty Advice</a>
                        </div>
                    </div>
            </div>
            <!-- <div fxShow="false" fxShow.gt-sm class="w-16">
                <a class="logo" routerLink="/" (click)="closeSubMenus()"><img src="assets/images/attiveg-logo-1.png" alt="image"></a>
            </div> -->
            <!-- <div fxFlex="50" fxShow="false" fxShow.gt-sm>
                <form [formGroup]="searchForm" class="rounded-md border border-gray-300 focus-within:border-[#fc2779] overflow-hidden h-10" fxLayout="row" (ngSubmit)="search($event)">
                    <button mat-raised-button [matMenuTriggerFor]="categoriesMenu"
                        #categoriesMenuTrigger="matMenuTrigger" type="button"
                        class="mat-elevation-z0 categories text-truncate text-default px-3">{{selectedCategory?.name}}<mat-icon>arrow_drop_down</mat-icon></button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="categories-dropdown">
                        <span *ngIf="categories" (mouseleave)="categoriesMenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="selectedCategory?.parentId"
                                (change)="changeCategory($event)"></app-category-list>
                        </span>
                    </mat-menu>
                    <input formControlName="search" name="search" type="text" placeholder="Type to search..." fxFlex class="px-3 border-l border-gray-300 focus-within:border-[#fc2779] !outline-0">
                    <button type="submit"
                        class="theme-bg-color px-3 h-100 flex items-center justify-center text-white">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div> -->
            <div class="flex items-center gap-12">
                <form [formGroup]="searchForm" class="bg-[#9797971a] rounded border border-transparent focus-within:border-[#fc2779] focus-within:bg-transparent overflow-hidden h-10 px-2 transition-all max-xl:!hidden" fxLayout="row" (ngSubmit)="search($event)">
                    <svg class="w-6 fill-gray-400" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.54 19.97L16.3 14.73C17.36 13.44 17.99 11.79 17.99 9.99C17.99 5.85 14.64 2.5 10.5 2.5C6.35 2.5 3 5.85 3 9.99C3 14.13 6.35 17.48 10.49 17.48C12.29 17.48 13.94 16.84 15.23 15.79L20.47 21.03C20.62 21.18 20.81 21.25 21 21.25C21.19 21.25 21.38 21.18 21.53 21.03C21.83 20.74 21.83 20.26 21.54 19.97ZM10.49 15.98C7.19 15.98 4.5 13.29 4.5 9.99C4.5 6.69 7.19 4 10.49 4C13.79 4 16.48 6.69 16.48 9.99C16.48 13.3 13.8 15.98 10.49 15.98Z"></path></svg>
                    <input formControlName="search" name="search" type="text" placeholder="Search on Attiveg" fxFlex class="px-3 !outline-0 bg-transparent max-w-[180px]">
                    <!-- <button type="submit"
                        class="bg-transparent px-3 h-100 flex items-center justify-center text-white">
                        <mat-icon>search</mat-icon>
                    </button> -->
                </form>
            
            <div class="flex items-center gap-8">
                <app-top-menu></app-top-menu>
            
                <div [@.disabled]="true">
                    <button [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger">
                        <!-- <mat-icon fxShow="false" style="color: #1976d2;" class="cart-icon" fxShow.gt-sm class="mat-icon-lg">shopping_cart</mat-icon>
                        <mat-icon fxHide="false" style="color: #1976d2;" class="cart-icon" fxHide.gt-sm class="mat-icon-md">shopping_cart</mat-icon> -->
                        <div class="relative flex flex-col items-center gap-1 max-sm:gap-0.5 hover:text-[#fc2779] hover:fill-[#fc2779] font-semibold">
                            <span class="relative">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 max-sm:w-4 max-sm:h-4 inline hover:fill-[#fc2779]" viewBox="0 0 512 430">
                                    <path d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0" data-original="#000000"></path>
                                </svg>
                                <span class="absolute left-auto -ml-1 -top-1.5 rounded-full theme-bg-color px-1 py-0 text-xs text-white min-w-4">{{appService.Data.totalCartCount}}</span>
                            </span>
                            <div class="flex items-center relative">
                                <span class="max-sm:text-xs">Cart</span>
                                <svg class="w-3 fill-current absolute -right-5" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.00068 5.50006L0.464844 1.96422L1.64401 0.785889L4.00068 3.14339L6.35734 0.785889L7.53651 1.96422L4.00068 5.50006Z"></path>
                                </svg>
                            </div>
                        </div>

                        <!-- <span *ngIf="appService.Data.totalCartCount > 0"
                            class="cart-items-count">{{appService.Data.totalCartCount}}</span> -->
                        <!-- <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
                            class="top-cart">
                            <span *ngIf="appService.Data.totalCartCount > 0">
                                <bdi>
                                    ₹<span>{{appService.Data.totalPrice | number : '1.2-2'}}</span>
                                </bdi>
                            </span>
                        </div> -->
                        <!-- <mat-icon fxShow="false" style="color: #1976d2;" fxShow.gt-sm class="mat-icon-lg">arrow_drop_down</mat-icon> -->
                    </button>
                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="top-menu-dropdown cart-dropdown popup-shadow !px-5 !py-3 !max-w-80 !w-80 mt-3">
                        <ng-template matMenuContent class="!m-0">
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-light">
                                    <span class="!capitalize font-bold">{{appService.Data.totalCartCount}} ITEM<span
                                                *ngIf="appService.Data.totalCartCount > 1">S</span></span>
                                    <a [routerLink]="['/cart']" class="theme-color !capitalize font-bold">VIEW CART</a>
                                </div>
                                <div class="divider mt-2"></div>
                                <div *ngIf="appService.Data.totalCartCount == 0" class="py-4 text-light text-center">You
                                    have no items in your shopping cart.</div>
                                <mat-list class="!py-3">
                                    <mat-list-item *ngFor="let product of appService.Data.cartList">
                                        <h3 matLine class="text-light"> {{product.name}} </h3>
                                        <p matLine class="text-light">
                                            <bdi>{{product.cartCount}} x ₹{{product.newPrice | number : '1.2-2'}}</bdi>
                                        </p>
                                        <img [src]="product.images[0].small" alt="image">
                                        <button mat-icon-button color="warn" class="remove"
                                            (click)="remove(product);stopClickPropagate($event)" matTooltip="Remove"
                                            matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="appService.Data.cartList.length > 0" class="cart-dropdown-footer">
                                    <div class="divider"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-light font-bold !py-3">
                                        TOTAL:
                                        <span class="new-price font-bold theme-color">₹{{appService.Data.totalPrice | number : '1.2-2'}}</span>
                                    </div>
                                    <div class="divider"></div>

                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-5">
                                        <button class="px-4 py-2 bg-red-600 text-white flex items-center gap-2 rounded" (click)="clear();stopClickPropagate($event)"
                                            matTooltip="Clear All" matTooltipPosition="after">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline fill-white" viewBox="0 0 512.007 512.007">
                                                <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="30">
                                                  <path d="M15 21.403h74.566L120.3 130.9" data-original="#000000"/>
                                                  <circle cx="438.227" cy="457.108" r="33.496" data-original="#000000" transform="rotate(-22.5 438.207 457.09)"/>
                                                  <circle cx="180.144" cy="457.108" r="33.496" data-original="#000000" transform="rotate(-76.714 180.17 457.136)"/>
                                                  <path d="M181.079 358.309c-17.983 0-32.562 14.578-32.562 32.562v0c0 17.983 14.578 32.562 32.562 32.562h257.148M120.3 130.9l60.184 227.409h256.34L497.007 130.9H120.3m233.552 113.704h-90.397" data-original="#000000"/>
                                                </g>
                                              </svg>
                                              Clear all
                                        </button>
                                        <button [routerLink]="user ? ['/checkout']: ['/sign-in']"
                                            [queryParams]="user ? {} : {redirect: 'checkout'}"
                                            class="theme-bg-color px-4 py-2 text-white flex items-center gap-2 rounded" matTooltip="Checkout" matTooltipPosition="before">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline fill-white" viewBox="0 0 512 512">
                                                <path d="M196.86 226.81h-59.73a15 15 0 0 0 0 30h59.73a15 15 0 0 0 0-30zm-128.37 30h9.8a15 15 0 1 0 0-30h-9.8a15 15 0 1 0 0 30zM196.86 291H68.49a15 15 0 1 0 0 30h128.37a15 15 0 0 0 0-30z" data-original="#000000"/>
                                                <path d="M497 238.33q-3.53 0-7-.26V96a35 35 0 0 0-35-35H35A35 35 0 0 0 0 96v270.23a35 35 0 0 0 35 35h312.88a137.17 137.17 0 0 0 63.89 48.83 15 15 0 0 0 10.46 0A137.85 137.85 0 0 0 512 320.86v-67.53a15 15 0 0 0-15-15zM35 91h420a5 5 0 0 1 5 5v50.58H30V96a5 5 0 0 1 5-5zm-5 275.23V176.58h430V231a96.81 96.81 0 0 1-18.86-10.45l-15.46-11a15 15 0 0 0-17.36 0l-15.46 11A96 96 0 0 1 337 238.33a15 15 0 0 0-15 15v67.53a137.27 137.27 0 0 0 9.52 50.37H35a5 5 0 0 1-5-5zm452-45.37a108.37 108.37 0 0 1-65 99 108.37 108.37 0 0 1-65-99v-53.41A125.67 125.67 0 0 0 410.23 245l6.77-4.81 6.77 4.81A125.67 125.67 0 0 0 482 267.45z" data-original="#000000"/>
                                                <path d="M398.26 312.74a15 15 0 0 0-22.52 19.83l23.67 26.86a15 15 0 0 0 22.51 0l36.34-41.25a15 15 0 0 0-22.52-19.83l-25.08 28.47z" data-original="#000000"/>
                                              </svg>
                                              Checkout
                                        </button>
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>
                </div>
                <div fxLayoutAlign="center center" [@.disabled]="true" class="">
                <button type="button" [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger" class="hidden max-xl:block max-xl:pl-2.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 max-sm:w-4 max-sm:h-4 inline hover:fill-[#fc2779]"  viewBox="0 0 6.35 6.35">
                        <path d="M2.845.034A2.813 2.813 0 0 0 .037 2.842a2.815 2.815 0 0 0 2.808 2.813c.661 0 1.27-.232 1.751-.617l1.17 1.17a.313.313 0 0 0 .442-.442L5.04 4.596a2.797 2.797 0 0 0 .617-1.753 2.816 2.816 0 0 0-2.81-2.81zm0 .625c1.211 0 2.186.974 2.186 2.183a2.185 2.185 0 1 1-4.37.003A2.18 2.18 0 0 1 2.845.659z" data-original="#000000" paint-order="stroke fill markers"/>
                    </svg>
                </button>
                <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="bg-white rounded-none popup-shadow !min-h-full !p-2 top-4">
                        <form [formGroup]="searchForm" class="bg-[#9797971a] rounded border border-transparent focus-within:border-[#fc2779] focus-within:bg-transparent overflow-hidden h-10 px-2 transition-all" fxLayout="row" (ngSubmit)="search($event)">
                            <svg class="w-6 fill-gray-400" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.54 19.97L16.3 14.73C17.36 13.44 17.99 11.79 17.99 9.99C17.99 5.85 14.64 2.5 10.5 2.5C6.35 2.5 3 5.85 3 9.99C3 14.13 6.35 17.48 10.49 17.48C12.29 17.48 13.94 16.84 15.23 15.79L20.47 21.03C20.62 21.18 20.81 21.25 21 21.25C21.19 21.25 21.38 21.18 21.53 21.03C21.83 20.74 21.83 20.26 21.54 19.97ZM10.49 15.98C7.19 15.98 4.5 13.29 4.5 9.99C4.5 6.69 7.19 4 10.49 4C13.79 4 16.48 6.69 16.48 9.99C16.48 13.3 13.8 15.98 10.49 15.98Z"></path></svg>
                            <input formControlName="search" name="search" type="text" placeholder="Search on Attiveg" fxFlex class="px-3 !outline-0 bg-transparent" (click)="stopClickPropagate($event)">
                        </form>
                </mat-menu>
                </div>
                <!-- <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true">
                    <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                        class="search-toggle-btn">
                        <mat-icon class="mat-icon-md">search</mat-icon>
                    </button>
                    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="search-dropdown mat-elevation-z8">
                        <ng-template matMenuContent>
                            <form [formGroup]="searchForm" fxFlex class="search-form" (ngSubmit)="search($event)">
                                <button mat-raised-button [matMenuTriggerFor]="categories2Menu"
                                    #categories2MenuTrigger="matMenuTrigger" type="button"
                                    class="mat-elevation-z0 categories text-light"
                                    (click)="stopClickPropagate($event)">{{category?.name}}<mat-icon>arrow_drop_down</mat-icon></button>
                                <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before"
                                    class="categories-dropdown">
                                    <ng-template matMenuContent>
                                        <span *ngIf="categories" (mouseleave)="categoriesMenuTrigger.closeMenu()">
                                            <app-category-list [categories]="categories" [categoryParentId]="selectedCategory?.parentId"
                                                (change)="changeCategory($event)"></app-category-list>
                                        </span>
                                    </ng-template>
                                </mat-menu>
                                <input type="text" placeholder="Type to search..." fxFlex
                                    (click)="stopClickPropagate($event)">
                                <button mat-mini-fab (click)="search()" type="button"
                                    class="search-btn mat-elevation-z0 text-light">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form>
                        </ng-template>
                    </mat-menu>
                </div> -->
            </div>
            </div>
        </div>
    </div>



    <app-menu></app-menu>


    <div class="theme-container main !max-w-full w-full product-list-page">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </div>


    <!-- <app-options></app-options> -->

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


</mat-sidenav-container>