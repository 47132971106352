import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-section-carousel',
  templateUrl: './section-carousel.component.html',
  styleUrls: ['./section-carousel.component.scss']
})
export class SectionCarouselComponent implements OnInit {
  @ViewChild(SwiperDirective, { static: false }) swiperDirective: SwiperDirective;
  @Input('slideData') slideData: any
  public config: SwiperConfigInterface = {
    observer: false,
    slidesPerView: 'auto',
    spaceBetween: 16,
    keyboard: {
      enabled: true,
    },
    navigation: true,
    pagination: false,
    grabCursor: true,
    loop: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 10, // Number of slides to load before and after the active slide
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 3
      },
      200: {
        slidesPerView: 1
      }
    },
    speed: 500,
    effect: 'slide',
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    if (this.swiperDirective && this.swiperDirective.swiper()) {
      const swiperInstance = this.swiperDirective.swiper();
      swiperInstance.on('slideChangeTransitionStart', () => {
        swiperInstance.autoplay.stop();
        swiperInstance.autoplay.start();
      });
    }
  }

}
