import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency: any;
  user: any;

  public settings: Settings;
  constructor(public appSettings: AppSettings, public appService: AppService, public translateService: TranslateService, private activeRoute: ActivatedRoute, public router: Router, public snackBar: MatSnackBar, public auth: AuthService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.activeRoute.data.subscribe((data: any) => {
      this.user = data.user;
    });
    this.currency = this.currencies[0];
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(lang: string) {
    this.translateService.use(lang);
  }

  public getLangText(lang) {
    if (lang == 'de') {
      return 'German';
    }
    else if (lang == 'fr') {
      return 'French';
    }
    else if (lang == 'ru') {
      return 'Russian';
    }
    else if (lang == 'tr') {
      return 'Turkish';
    }
    else {
      return 'English';
    }
  }

  logout() {
    this.auth.logout().subscribe({
      next: (res: any) => {
        localStorage.removeItem("access_token");
        this.router.navigate(['/'])
          .then(() => {
            window.location.reload();
          });
      },
      error: (err: any) => {
        this.snackBar.open('Check it out!, Something went wrong', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    });
  }

  checkLogin(){
    if(this.user == null){
      this.router.navigate(['/sign-in'])
      
    }
  }
}
