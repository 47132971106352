import { Injectable } from "@angular/core";
import {
	Resolve,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
	providedIn: "root",
})
export class AdminResolverService implements Resolve<any> {
	userDetails: any;
	loggedIn: boolean;
	constructor(
		private http: HttpClient,
		private auth: AuthService,
		private route: Router
	) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> {
		this.auth.checkLogin();
		this.auth.isUserLoggedIn.subscribe((val) => {
			if (val) {
				this.loggedIn = true;
			} else {
				this.loggedIn = false;
			}
		});
		if (this.loggedIn) {
			this.userDetails =
				this.auth.getCurrentUser() != null
					? this.auth.getCurrentUser()
					: route.parent.data.user;
			if (this.userDetails && this.userDetails.authorities[0].name == "ROLE_ADMIN") {
				return this.userDetails;
			} else {
				this.route.navigate([""]);
				return this.userDetails;
			}
		} else {
			this.route.navigate([""]);
			return this.userDetails;
		}
	}
}