<!-- <div class="menu-item">
    <a mat-button fxLayout="row" fxLayoutAlign="start center" routerLink="/" routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact:true}" (click)="onClick(0)" [id]="'menu-item-0'">
        <span class="menu-title">{{ 'HOME' | translate }}</span>
    </a>
</div> -->
<div class="space-y-4">
    <div *ngFor="let category of categories; let i = index" class="menu-item">
        <a *ngIf="!category.hasSubCategory && !category.parentId" class="relative" fxLayout="row" fxLayoutAlign="start center"
        [routerLink]="['/products', category.slug]" routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact:true}" (click)="onClick(category.id)" [id]="'menu-item-'+category.id">
        <span class="menu-title capitalize text-light">{{ category.name | translate }}</span>
    </a>
    <a *ngIf="category.hasSubCategory" class="relative" fxLayout="row" fxLayoutAlign="start center"
    (click)="onClick(category.id)" [id]="'menu-item-'+category.id">
    <span class="menu-title text-default font-semibold">{{ category.name | translate }}</span>
    <svg viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg" class="w-3 fill-current ml-auto menu-expand-icon transition-all duration-300">
        <path d="M4.00068 5.50006L0.464844 1.96422L1.64401 0.785889L4.00068 3.14339L6.35734 0.785889L7.53651 1.96422L4.00068 5.50006Z"></path>
    </svg>
</a>

<div *ngIf="category.hasSubCategory" class="sub-menu mt-2" [id]="'sub-menu-'+category.id">
    <a *ngFor="let subCategory of category.subCategories" class="relative !bg-transparent" fxLayout="row" fxLayoutAlign="start center"
    [routerLink]="['/products', subCategory.slug]" routerLinkActive="active-link"
    [routerLinkActiveOptions]="{exact:true}" (click)="onClick(subCategory.id)"
    [id]="'menu-item-'+subCategory.id">
    <span class="menu-title capitalize text-light">{{ subCategory.name | translate }}</span>
</a>
</div>
</div>
</div>