import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count: number = 1;
  public align = 'center center';
  isAnimate: boolean = false;
  user: any;
  constructor(public auth: AuthService, public appService: AppService, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    if (this.product) {
      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }
    }
    this.layoutAlign();
    this.user = this.auth.getCurrentUser();
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      this.align = 'center center';
    }
  }



  public increment(count) {
    if (this.count < this.product.availabilityCount) {
      this.count++;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
      this.changeQuantity(obj);
    }
    else {
      this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }

  public decrement(count) {
    if (this.count > 1) {
      this.count--;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
      this.changeQuantity(obj);
    }
  }

  public addToWishList(product: Product) {
    this.appService.addToWishList(product);
    this.isAnimate = true;
    setTimeout(() => {
      this.isAnimate = false
    }, 5000)
  }

  public addToCart(product: Product) {
    let currentProduct = this.appService.Data.cartList.filter(item => item.id == product.id)[0];
    if (currentProduct) {
      if ((currentProduct.cartCount + this.count) <= this.product.availabilityCount) {
        product.cartCount = currentProduct.cartCount + this.count;
      }
      else {
        this.snackBar.open('You can not add more items than available. In stock ' + this.product.availabilityCount + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    }
    else {
      product.cartCount = this.count;
    }
    this.appService.addToCart(product);
  }

  public buyNow(product: Product) {
    let currentProduct = this.appService.Data.cartList.filter(item => item.id == product.id)[0];
    if (currentProduct) {
      if ((currentProduct.cartCount + this.count) <= this.product.availabilityCount) {
        product.cartCount = currentProduct.cartCount + this.count;
      }
      else {
        this.snackBar.open('You can not add more items than available. In stock ' + this.product.availabilityCount + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    }
    else {
      product.cartCount = this.count;
    }
    this.appService.addToCart(product, true);
    if (this.user) {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/sign-in'], {
        queryParams: { redirect: 'checkout' }
      });
    }
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

  public isInwishList(id) {
    let el = this.appService.Data.wishList.find(el => el.id === id);
    if (el?.id) return true
    else return false
  }

}