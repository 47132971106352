<div class="main-slider mt-2 px-12 max-lg:px-0 relative">
  <div class="swiper-container"  [swiper]="config">
    <div class="swiper-wrapper flex max-w-full">      
      <div *ngFor="let slide of slides" class="swiper-slide max-w-full max-h-[446px] overflow-hidden">
        <a style="text-decoration:none" href="{{slide.url}}" class="block w-full max-w-full">
          <!-- <div [attr.data-background]="slide.image" class="slide-item swiper-lazy rounded"> -->
            <img src="{{slide.image}}" class="slide-item swiper-lazy rounded max-w-full !h-auto" srcset="{{slide.image}} 600w" sizes="100vw"/>  
            <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              <!-- <div class="mask"></div> -->
              <!-- <div fxLayout="column" fxLayoutAlign="center center" class="content">
                <h1>{{slide.title}}</h1>
                <h3>{{slide.subtitle}}</h3>
                <button mat-raised-button color="primary">Shop now</button>
              </div>  -->
          <!-- </div> -->
        </a>
      </div>
    </div>
    <!-- <div class="swiper-pagination white"></div> -->
  </div>
  <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 max-lg:left-1.5 bg-white border-2 border-c rounded-full swiper-button-prev swipe-arrow"><mat-icon class="text-default">keyboard_arrow_left</mat-icon></button>
  <button class="w-12 h-12 max-sm:w-8 max-sm:h-8 max-lg:right-1.5 bg-white border-2 border-c rounded-full swiper-button-next swipe-arrow"><mat-icon class="text-default">keyboard_arrow_right</mat-icon></button>
</div>