import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 28.895515;
  public lng: number = 76.606613;
  public zoom: number = 12;

  constructor() { }

  ngOnInit() { }

  subscribe(){ }

}