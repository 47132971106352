import { Component, OnInit, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [SidenavMenuService]
})
export class SidenavMenuComponent implements OnInit {
  //@Input('categories') categories;
  //@Input('parentId') parentId;
  //parentCategories:Array<any>;
  categories: any = [];

  constructor(private sidenavMenuService: SidenavMenuService, private activeRoute: ActivatedRoute, public appService: AppService) { }

  ngOnInit() {
    this.getCategories();
    //this.parentCategories = this.categories.filter(category => category.parentId == this.parentId);
  }

  public getCategories() {
    this.activeRoute.data.subscribe((data: any) => {
      data.pages.categories.forEach((category: any) => {
        if (category.slug != 'all') {
          this.categories.push(category);
        }
      });
      this.categories.forEach((category: any, index: any) => {
        if (category.hasSubCategory) {
          category.subCategories = this.categories.filter((cat: any) => cat.parentId == category.id);
        }
      });
      //this.category = data[0];
      this.appService.Data.categories = this.categories;
    });
  }

  onClick(categoryId) {
    this.sidenavMenuService.toggleMenuItem(categoryId);
    this.sidenavMenuService.closeOtherSubMenus(this.categories, categoryId);
  }

}
